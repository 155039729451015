@import "../_variables.scss";

// read here: https://www.ag-grid.com/vue-data-grid/theming-v32-customisation-variables/#reference-variables---ag-odd-row-background-color

.input-search {
    width: 100%;
    padding: 8px; 
    box-sizing: border-box; 
    border: 1px solid #ddddde;
    border-radius: 7px;
}

//^ DARK THEME

// general theme
.ag-theme-quartz-dark {
    font-family: "Open Sans", sans-serif !important;
    --ag-font-size: 14px !important;
    --ag-cell-widget-spacing: 5px !important;
    --ag-cell-horizontal-padding: 10px !important;
    --ag-border-radius: 4px !important;
    
    --ag-background-color: var(--dark-table-row-bg-color) !important;
    --ag-odd-row-background-color: var(--dark-table-odd-row-bg-color) !important;
    // --ag-row-hover-color: var(--dark-table-hover-row-bg-color) !important;
    // --ag-column-hover-color: var(--dark-table-hover-row-bg-color) !important;
    // --ag-header-cell-hover-background-color: var(--dark-table-hover-row-bg-color) !important;
    --ag-alpine-active-color: var(--dark-table-hover-row-bg-color) !important;
    --ag-row-border-color: var(--dark-table-border-color) !important;
    --ag-header-column-separator-color: var(--dark-table-border-color) !important;
    --ag-header-background-color: var(--dark-table-header-bg-color) !important;
}

// search input above the table
.ag-theme-quartz-dark .input-search {
    background-color: var(--dark-table-search-bg-color) !important;
    border: 1px solid var(--dark-table-border-color) !important;

    &:focus-visible{
       outline-style: none !important;
       background-color: var(--dark-table-hover-search-bg-color) !important;
    }
}

// left header part
.ag-theme-quartz-dark .ag-pinned-left-header {
    background-color: var(--dark-table-header-bg-color) !important;
}

// middle header part
.ag-theme-quartz-dark .ag-header-container {
    background-color: var(--dark-table-header-bg-color) !important;
}

// right header part
.ag-theme-quartz-dark .ag-pinned-right-header {
    background-color: var(--dark-table-header-bg-color) !important;
}

// normal row 
.ag-theme-quartz-dark .ag-body .ag-body-viewport {
    background-color: var(--dark-table-row-bg-color) !important;
}

// pagination
.ag-theme-quartz-dark .ag-paging-panel {
    border-top-color: var(--dark-table-border-color) !important;
    background-color: var(--dark-table-pagination-bg-color) !important;
    color: var(--dark-table-font-color) !important;
}

// loading row
.ag-theme-quartz-dark .ag-row-loading {
    background-color: var(--dark-table-row-bg-color) !important;
    color: var(--dark-table-font-color) !important;
}


//^ WHITE THEME

// general theme
.ag-theme-quartz-light {
    font-family: "Open Sans", sans-serif !important;
    --ag-font-size: 14px !important;
    --ag-cell-widget-spacing: 5px !important;
    --ag-cell-horizontal-padding: 10px !important;
    --ag-border-radius: 4px !important;

    --ag-odd-row-background-color: var(--light-table-odd-row-bg-color) !important;
    // --ag-row-hover-color: var(--light-table-hover-row-bg-color) !important;
    // --ag-column-hover-color: var(--light-table-hover-row-bg-color) !important;
    // --ag-header-cell-hover-background-color: var(--light-table-hover-row-bg-color) !important;
    --ag-alpine-active-color: var(--light-table-hover-row-bg-color) !important;
    --ag-row-border-color: var(--light-table-border-color) !important;
    --ag-header-column-separator-color: var(--light-table-border-color) !important;
    --ag-header-background-color: var(--light-table-header-bg-color) !important;
}

// search input above the table
.ag-theme-quartz-light .input-search {
    background-color: var(--light-table-search-bg-color) !important;
    border: 1px solid var(--light-table-border-color) !important;

    &:focus-visible{
       outline-style: none;
       background-color: var(--light-table-hover-search-bg-color) !important;
    }
}

// left header part
.ag-theme-quartz-light .ag-pinned-left-header {
    background-color: var(--light-table-header-bg-color) !important;
}

// middle header part
.ag-theme-quartz-light .ag-header-container {
    background-color: var(--light-table-header-bg-color) !important;
}

// right header part
.ag-theme-quartz-light .ag-pinned-right-header {
    background-color: var(--light-table-header-bg-color) !important;
}

// normal row
.ag-theme-quartz-light .ag-body .ag-body-viewport {
    background-color: var(--light-table-row-bg-color) !important;
}

// pagination
.ag-theme-quartz-light .ag-paging-panel {
    border-top-color: var(--light-table-border-color) !important;
    background-color: var(--light-table-pagination-bg-color) !important;
    color: var(--light-table-font-color) !important;
}

// loading row
.ag-theme-quartz-light .ag-row-loading {
    background-color: var(--light-table-row-bg-color) !important;
    color: var(--light-table-font-color) !important;
}

//^ COMMON STYLES

// Center all columns by default
.center-column {
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
}

// Ensure the content inside cells maintains its alignment
.center-column > div,
.center-column > span {
  width: 100%;
  text-align: center;
}

.topic-column > div,
.topic-column > span {
  width: 100%;
  text-align: left;
}

.ag-header-cell-center {
    .ag-header-cell-label {
        justify-content: center;
    }
}

